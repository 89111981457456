import { graphql } from 'gatsby';
import React, { FC } from 'react';
import {
    CaseTilesFragment,
    CTAFragment,
    SolutionsOverviewFragment,
    SolutionsPageFragment,
    SolutionsPageQuery,
} from '../entities/operationResults';
import { removeTags } from '../utils/removeTags';
import { Section } from './atoms/Section';
import Hero from './organisms/Hero';
import Intro from './organisms/Intro';
import Features from './organisms/Features';
import CaseTiles from './organisms/CaseTiles';
import Smart from './organisms/Smart';
import Supportive from './organisms/Supportive';
import Conclusion from './organisms/Conclusion';
import Footer from './organisms/Footer';
import CTA from './molecules/CTA';
import Meta from './atoms/Meta';
import theme from '../constants/theme';
import withDefaultTransition from '../hocs/withDefaultTransition';
import VacancyList from './molecules/VacancyList';

export const query = graphql`
    query SolutionsPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...SolutionsPageFragment
            }
            entries(site: $site, type: Solution) {
                ...SolutionsOverviewFragment
            }
        }
    }

    fragment SolutionsOverviewFragment on Craft_Solution {
        id
        title
        uri
    }
    fragment SolutionsPageFragment on Craft_Solutions {
        id
        title
        hero {
            ...HeroFragment
        }
        heading
        intro {
            ...IntroFragment
        }
        featuresTitle
        features {
            ...FeatureFragment
        }
        intermittentInformation {
            ...IntermittentInformationFragment
        }
        cases {
            ...CaseTilesFragment
        }
        smart {
            ...SmartFragment
        }
        supportive {
            ...SupportiveFragment
        }
        conclusion {
            ...ConclusionFragment
        }
        directContact {
            ...DirectContactFragment
        }
        callToAction {
            ...CTAFragment
        }
        seo {
            ...MetaFragment
        }
    }

    fragment IntermittentInformationFragment on Craft_IntermittentInformationSuperTableBlockType {
        text {
            content
        }
        subText {
            content
        }
    }
`;

interface Props {
    data: SolutionsPageQuery;
}

const Solutions: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as SolutionsPageFragment;

    const title = entry.title;
    const heading = entry.heading;
    const hero = entry.hero && entry.hero[0];
    const intro = entry.intro && entry.intro[0];
    const { featuresTitle, features } = entry;
    const info = entry.intermittentInformation && entry.intermittentInformation[0];
    const ctaList = (entry.callToAction || []) as CTAFragment[];
    const smart = entry.smart && entry.smart[0];
    const supportive = entry.supportive && entry.supportive[0];
    const conclusion = entry.conclusion && entry.conclusion[0];
    const footer = entry.directContact && entry.directContact[0];

    const solutions = data.craft.entries?.filter((solution): solution is SolutionsOverviewFragment => !!solution) ?? [];

    const meta = entry.seo && entry.seo[0];
    const metaDescription = hero && hero.description && removeTags(hero.description.content);

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={meta && meta.seoDescription ? meta.seoDescription : metaDescription}
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <Section paddingRight={2}>{hero && <Hero data={hero} ratio={759 / 1168} />}</Section>
            {solutions && solutions.length > 0 && (
                <Section background={theme.colors.shark} fontColor={theme.colors.white}>
                    <VacancyList solutions={solutions} header={heading} />
                </Section>
            )}
            <Section paddingLeft={2} leadingCTA>
                {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} />}
                {intro && <Intro data={intro} variant="solution" />}
            </Section>
            {features && <Features title={featuresTitle} data={features} />}
            {info && (
                <Section paddingLeft={4} leadingCTA>
                    <Intro data={info} variant="solution" />
                    {ctaList[1] && <CTA sourceId={entry.id} data={ctaList[1]} align="right" />}
                </Section>
            )}
            <Section background={theme.colors.shark} fontColor={theme.colors.white} paddingLeft={4} leadingCTA>
                <CaseTiles data={(entry.cases || []).filter((tile): tile is CaseTilesFragment => !!tile)} />
            </Section>
            <Section paddingLeft={3} leadingCTA>
                {ctaList[2] && <CTA sourceId={entry.id} data={ctaList[2]} />}
                {smart && <Smart data={smart} />}
                {supportive && <Supportive data={supportive} />}
            </Section>
            {conclusion && (
                <Section>
                    <Conclusion data={conclusion} />
                </Section>
            )}
            <Section variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} />}</Section>
        </>
    );
};

export default withDefaultTransition(Solutions);
